import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.widgetAlert': {
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export default StyledBox;
