import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';

const useHandleNavigationToRPPage = () => {
  const appNavigator = useAppNavigator();

  const navigateToCorrectRPPage = (
    redirectState,
    referralQueries,
    continueWithWidget,
  ) => {
    if (continueWithWidget) {
      appNavigator.push(PATHS.reservationPath[redirectState](referralQueries));
    } else {
      window.open(
        appNavigator.appendQuery(
          appNavigator.getUrl(
            undefined,
            PATHS.reservationPath[redirectState]().url,
            false,
          ),
          referralQueries,
        ),
        '_blank',
        'noopener,noreferrer',
      );
    }
  };

  const getReferralQueries = (referral) => {
    if (referral) {
      return {
        utm_campaign: `${referral.name} White Label`,
        utm_medium: 'affiliate',
        utm_source: referral.redirect_url,
        referral_code: referral.code,
      };
    }
    return {};
  };

  const navigateToNextPage = (
    reservation,
    referral,
    continueWithWidget = true,
  ) => {
    const { state } = reservation;
    const referralQueries = getReferralQueries(referral);
    navigateToCorrectRPPage(state, referralQueries, continueWithWidget);
  };
  return { navigateToNextPage };
};

export default useHandleNavigationToRPPage;
