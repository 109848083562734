import React from 'react';
import { Alert } from '@mui/material';
import StyledBox from 'views/common/components/UI/MainWidget/WidgetAlert/WidgetAlert.styles';

const WidgetAlert = (props) => {
  const { alertMassage } = props;

  return (
    <StyledBox className="widgetAlert">
      <Alert severity="error">{alertMassage}</Alert>
    </StyledBox>
  );
};
export { WidgetAlert };
