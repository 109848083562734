import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MainWidgetTextField } from 'views/common/components/UI/MainWidget/MainWidgetTextField';

const StyledGrid = styled(Grid)(() => ({}));

export const StyledMainWidgetTextField = styled(MainWidgetTextField)(
  ({ theme }) => ({
    '& label': {
      left: 'unset',
    },
    '& .MuiInputBase-input.MuiFilledInput-input': {
      paddingLeft: `${theme.spacing(0.5)} !important`,
    },
    '& .MuiFilledInput-root': {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  }),
);

export default StyledGrid;
