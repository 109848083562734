import React, { useState } from 'react';
import { AnnouncementBar } from 'views/common/components';
import {
  WidgetAlert,
  WidgetWarning,
} from 'views/common/components/UI/MainWidget';
import { WidgetTitle } from 'views/common/components/UI/MainWidget/WidgetTitle';
import { RENTAL_WIDGET, TOUR_WIDGET } from 'lib/utils/mainWidgetConstants';
import {
  TourWidgetWrapper,
  RentalWidgetWrapper,
} from 'views/common/components/Logical/MainWidgetWrapper';
import StyledBox from 'views/common/components/UI/MainWidget/MainWidget.styles';

const MainWidget = (props) => {
  const {
    shouldHideTitle,
    vertical,
    className,
    alertMassage,
    titleColor,
    announcementBar,
    widgetInfo,
    handleWidgetMenuSelect,
    handleRentalWidgetSearchClick,
    handleTourWidgetSearchClick,
    renderAllWidget = true,
    handleRenderAllWidget = () => {},
    renderAllProductOptionsProp = true,
  } = props;

  const [rentalWidgetWarnings, setRentalWidgetWarnings] = useState([]);
  const [renderAllProductOptions, setRenderAllProductOptions] = useState(
    renderAllProductOptionsProp,
  );

  const updateRenderAllProductOptions = (showAllproductOptions) => {
    if (renderAllProductOptionsProp) {
      setRenderAllProductOptions(showAllproductOptions);
    }
  };

  const updateRentalWidgetWarnings = (warnings) => {
    setRentalWidgetWarnings(warnings);
  };

  // eslint-disable-next-line consistent-return
  const renderAnnouncment = () => {
    if (announcementBar) {
      return <AnnouncementBar vertical={vertical} />;
    }
  };
  const renderWidget = () => {
    const rentalWidget = (
      <RentalWidgetWrapper
        {...props}
        vertical={vertical}
        renderAllWidget={renderAllWidget}
        handleRenderAllWidget={handleRenderAllWidget}
        handleRentalWidgetSearchClick={handleRentalWidgetSearchClick}
        updateRentalWidgetWarnings={updateRentalWidgetWarnings}
        updateRenderAllProductOptions={updateRenderAllProductOptions}
      />
    );
    switch (widgetInfo.type) {
      case RENTAL_WIDGET:
        return rentalWidget;
      case TOUR_WIDGET:
        return (
          <TourWidgetWrapper
            {...props}
            tourType={widgetInfo.tourType}
            vertical={vertical}
            renderAllWidget={renderAllWidget}
            handleRenderAllWidget={handleRenderAllWidget}
            handleTourWidgetSearchClick={handleTourWidgetSearchClick}
          />
        );
      default:
        return rentalWidget;
    }
  };
  return (
    <StyledBox
      className={`mainWidget ${className}`}
      renderAllWidget={renderAllWidget}>
      {rentalWidgetWarnings.length > 0 && (
        <WidgetWarning alertMessage={rentalWidgetWarnings} />
      )}

      {!shouldHideTitle && (
        <WidgetTitle
          widgetInfo={widgetInfo}
          vertical={vertical}
          titleColor={titleColor}
          handleWidgetMenuSelect={handleWidgetMenuSelect}
          renderAllProductOptions={renderAllProductOptions}
        />
      )}
      {alertMassage && <WidgetAlert alertMassage={alertMassage} />}
      {renderWidget()}
      {renderAllWidget && renderAnnouncment()}
    </StyledBox>
  );
};

export { MainWidget };
