import React, { useState, useEffect, useMemo } from 'react';
import axios, { API } from 'lib/config/axios';
import { TourWidget } from 'views/common/components/UI/MainWidget';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import PATHS from 'lib/navigation/paths';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { TOUR_WIDGET_FIELDS } from 'lib/utils/mainWidgetConstants';
import { WL_SS_KEY } from 'views/common/components/Logical/MainWidgetWrapper/utils/constants';

const initTourWidgetData = {
  selectedCountry: null,
  selectedCity: null,
  selectedTour: null,
  countries: [],
  cities: [],
  tours: [],
};

const TourWidgetWrapper = (props) => {
  const {
    tourType = null,
    handleTourWidgetSearchClick,
    whiteLabelWidget = false,
    initWidgetData = null,
    reservationData = null,
  } = props;
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();
  const [tourWidgetData, setTourWidgetData] = useState(initTourWidgetData);
  const [firstRender, setFirstRender] = useState(true);

  const getTourWidgetData = (queryParams) => {
    let params = queryParams;
    if (!params) {
      params = {
        country_id: tourWidgetData.selectedCountry?.id,
        city_id: tourWidgetData.selectedCity?.id,
        tour_id: tourWidgetData.selectedTour?.id,
        tour_type_id: tourType,
      };
    }
    dispatch(setLoading(true));
    return axios
      .get(API.tours.search_widget, {
        params: {
          ...params,
          referral_code: reservationData?.referral_code || null,
        },
      })
      .then((res) => {
        setTourWidgetData((prevData) => ({
          ...prevData,
          ...res.data,
        }));
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const selectedOptions = useMemo(
    () => ({
      country: tourWidgetData.selectedCountry,
      city: tourWidgetData.selectedCity,
      tour: tourWidgetData.selectedTour,
    }),
    [tourWidgetData],
  );

  const adjustTourWidgetData = (fieldsToChange) => {
    if (Object.keys(fieldsToChange).length > 0) {
      setTourWidgetData((prevData) => ({
        ...prevData,
        ...fieldsToChange,
      }));
    }
  };

  const getWidgetParams = (fieldType, newValue) => {
    const fieldsToChange = {};
    switch (fieldType) {
      case TOUR_WIDGET_FIELDS.COUNTRY:
        fieldsToChange.selectedCountry = newValue;
        if (tourWidgetData.selectedCity) {
          if (
            newValue
            && tourWidgetData.selectedCity.country_id !== newValue.id
          ) {
            fieldsToChange.selectedCity = null;
          }
        }
        if (tourWidgetData.selectedTour) {
          if (
            newValue
            && tourWidgetData.selectedTour.country_id !== newValue.id
          ) {
            fieldsToChange.selectedTour = null;
          }
        }
        adjustTourWidgetData(fieldsToChange);
        return {
          tour_type_id: tourType,
          country_id: newValue != null ? newValue.id : '',
          city_id:
            fieldsToChange.selectedCity === null
              ? null
              : tourWidgetData.selectedCity?.id,
          tour_id:
            fieldsToChange.selectedTour === null
              ? null
              : tourWidgetData.selectedTour?.id,
        };
      case TOUR_WIDGET_FIELDS.CITY:
        fieldsToChange.selectedCity = newValue;
        if (tourWidgetData.selectedTour) {
          if (newValue && tourWidgetData.selectedTour.city_id !== newValue.id) {
            fieldsToChange.selectedTour = null;
          }
        }
        adjustTourWidgetData(fieldsToChange);
        return {
          country_id: tourWidgetData.selectedCountry?.id,
          tour_type_id: tourType,
          city_id: newValue != null ? newValue.id : '',
          tour_id:
            fieldsToChange.selectedTour === null
              ? null
              : tourWidgetData.selectedTour?.id,
        };
      case TOUR_WIDGET_FIELDS.TOUR:
        fieldsToChange.selectedTour = newValue;
        adjustTourWidgetData(fieldsToChange);
        return {
          country_id: tourWidgetData.selectedCountry?.id,
          city_id: tourWidgetData.selectedCity?.id,
          tour_type_id: tourType,
          tour_id: newValue != null ? newValue.id : '',
        };
      default:
        if (tourType === tourWidgetData?.tour_type?.id) {
          return {
            country_id: tourWidgetData.selectedCountry?.id,
            city_id: tourWidgetData.selectedCity?.id,
            tour_id: tourWidgetData.selectedTour?.id,
            tour_type_id: tourType,
          };
        }
        adjustTourWidgetData(initTourWidgetData);
        return {
          tour_type_id: tourType,
        };
    }
  };

  const handleSelectInputChange = async (fieldType, e, newValue) => {
    const params = getWidgetParams(fieldType, newValue);
    await getTourWidgetData(params);
  };
  const getRedirectionPath = () => {
    const tourTypeSlug = tourWidgetData.tour_type?.slug;
    if (selectedOptions.tour?.id) {
      const tourSlug = tourWidgetData.selectedTour.slug;
      return PATHS.tour.index(tourTypeSlug, tourSlug);
    }
    if (selectedOptions.city?.id) {
      const { selectedCity } = tourWidgetData;
      if (tourTypeSlug) {
        return PATHS.tour.index(tourTypeSlug, selectedCity.slug);
      }
      return PATHS.filteredMotorcycleTours(selectedCity.slug);
    }
    if (selectedOptions.country?.id) {
      const { selectedCountry } = tourWidgetData;

      if (tourTypeSlug) {
        return PATHS.tour.index(tourTypeSlug, selectedCountry.slug);
      }
      return PATHS.filteredMotorcycleTours(selectedCountry.slug);
    }
    if (tourTypeSlug) {
      return PATHS.tour.tourType(tourTypeSlug);
    }
    return PATHS.motorcycleTours.index({ q: ' ' });
  };

  const getReferralCode = () => appNavigator.query.ReferralCode || sessionStorage.getItem(WL_SS_KEY);

  const handleSearchButtonClick = () => {
    if (typeof handleTourWidgetSearchClick === 'function') {
      handleTourWidgetSearchClick(selectedOptions);
      return;
    }
    const redirectionPath = getRedirectionPath();
    if (whiteLabelWidget) {
      window.open(
        appNavigator.getUrl(
          undefined,
          getReferralCode()
            ? `${redirectionPath.url}?referral_code=${getReferralCode()}`
            : redirectionPath.url,
        ),
        '_blank',
        'noopener,noreferrer',
      );
    } else {
      appNavigator.push(redirectionPath);
    }
  };

  useEffect(() => {
    if (!initWidgetData && firstRender) {
      getTourWidgetData();
      setFirstRender(false);
    } else if (tourType) {
      handleSelectInputChange();
      setFirstRender(false);
    }
  }, [tourType]);

  useEffect(() => {
    if (initWidgetData) {
      setTourWidgetData(initWidgetData);
    }
  }, [initWidgetData]);

  return (
    <TourWidget
      countriesList={tourWidgetData.countries}
      toursList={tourWidgetData.tours}
      handleSelectInputChange={handleSelectInputChange}
      selectedOptions={selectedOptions}
      handleSearchButtonClick={handleSearchButtonClick}
      {...props}
    />
  );
};

export { TourWidgetWrapper };
