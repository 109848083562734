import React, { useState } from 'react';
import {
  Alert, AlertTitle, Collapse, Typography,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/MainWidget/WidgetWarning/WidgetWarning.styles';
import WarningIcon from '@mui/icons-material/Warning';
import Typography500 from 'views/common/components/UI/Typography500';
import { useTranslation } from 'next-i18next';

const WidgetWarning = (props) => {
  const { alertMessage } = props;
  const { t } = useTranslation('fe_er_common_main_widget');
  const [open, setOpen] = useState(true);
  return (
    <StyledBox className="widgetAlert" mb={2}>
      <Collapse in={open}>
        <Alert
          severity="warning"
          onClose={() => {
            setOpen(false);
          }}
          variant="filled"
          icon={<WarningIcon fontSize="small" />}>
          <AlertTitle>
            <Typography500 variant="body2" component="p">
              {t('fe_er_common_main_widget:warning')}
            </Typography500>
          </AlertTitle>
          <ul>
            {alertMessage.map((item) => (
              <li>
                <Typography variant="body2" component="p">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </Alert>
      </Collapse>
    </StyledBox>
  );
};
export { WidgetWarning };
