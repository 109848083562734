import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MuiAlert-root': {
    borderRadius: '0',
    color: theme.palette.grey[900],
  },
  '& ul': {
    paddingLeft: theme.spacing(1.5),
    margin: '0',
  },
}));

export default StyledBox;
